// @flow
import * as React from 'react'
import { graphql } from 'gatsby'

import { Content, Section, Meta } from '../components/Theme'
import Header from '../components/Header'
import Layout from '../components/Layout'

export type Props = {
  data: {
    project: {
      title: string,
      slug: string,
      introduction: {
        introduction: string
      },
      images: {
        resize: {
          src: string
        }
      },
      body: {
        markdown: {
          html: string
        }
      }
    }
  },
  location: any
}

export default (props: Props) => {
  const { project } = props.data
  const thumbnail =
    project.images && project.images[0] && project.images[0].resize.src

  return (
    <Layout location={props.location}>
      <div>
        <Meta title={project.title} image={thumbnail} />
        <Header
          title={project.title}
          introduction={
            project.introduction && project.introduction.introduction
          }
        />
        <Content>
          <Section
            dangerouslySetInnerHTML={{
              __html: project.body && project.body.markdown.html
            }}
          />
        </Content>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProjectQuery($slug: String!) {
    project: contentfulProject(slug: { eq: $slug }) {
      title
      slug
      introduction {
        introduction
      }
      images {
        resize(width: 900, height: 500, cropFocus: LEFT, quality: 85) {
          src
        }
      }
      body {
        markdown: childMarkdownRemark {
          html
        }
      }
    }
  }
`
